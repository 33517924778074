import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { Quote, eStatusM } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-received-quotes',
  templateUrl: './received-quotes.component.html',
  styleUrls: ['./received-quotes.component.scss']
})
export class ReceivedQuotesComponent {
  quoteF="";
  toDate:any;
  fromDate:any;
  customer ="";
  statusF = "-1";
  dateTimeString: string="";
  quoteListForSearch:any;
  filteredQuotes: any;
  emptySearch:boolean=false;
  quotes$!:Observable<any>
  quickSearchYeilds=false;


constructor(private metadataService: MetadataService,private endpointService:EndpointApiService,private router: Router){
  this.toDate=null;
  this.fromDate=null;
  
  //this.getQuotes();
}
ngOnInit(): void {
  this.metadataService.setTitle('default');
  this.metadataService.setDescription('default');

  // Get Quotes
  let reqBody = {
    "quote":"",
    "name":"",
    "createdAfter":null,
    "createdBefore":null,
    "status":-1    
  }
  this.endpointService.getQuotes(reqBody).subscribe((data)=>{
    this.quoteListForSearch=data;
    this.searchQuotes(true);
  })
}

fullSearch(){
  if (this.isFullSearchEnabled()){
  let reqBody = {
    "quote":this.quoteF,
    "createdAfter":this.fromDate,
    "createdBefore":this.toDate,
    "status":this.statusF   
  }
  this.emptySearch = false;
  this.quotes$ = this.endpointService.findQuotes(reqBody).pipe(
    
    tap((data:any)=> {
      this.quickSearchYeilds=false;
      if(data.length==0){
        this.emptySearch = true;
      }
    }),
    shareReplay(1)
  );
}
}

isFullSearchEnabled(): boolean {
  return (
    this.quoteF.trim() !== '' ||
    this.fromDate !== null||
    this.toDate !== null||
    this.statusF !== '-1'
  );
}


searchQuotes(onInitSearch?:boolean) {
  const searchKey = this.quoteF.replace(/\s+/g, '').trim().toLowerCase();
  const statusKey = this.statusF.trim();
  const fromDate = this.fromDate ? new Date(this.fromDate) : null;
  const toDate = this.toDate ? new Date(this.toDate) : null;

  // Parse statusKey as a number
  const statusNum = parseInt(statusKey, 10);

  if (searchKey || statusNum !== -1 || (fromDate && toDate)) {
    this.filteredQuotes = this.quoteListForSearch.filter((quote: any) => {
      const searchMatches = searchKey && (
        (quote.customerMobileNumber?.trim().toLowerCase().includes(searchKey) || false) ||
        (quote.customerCompanyName?.trim().toLowerCase().includes(searchKey) || false) ||
        (quote.customerName?.trim().toLowerCase().includes(searchKey) || false) ||
        (quote.jobId?.trim().toLowerCase().includes(searchKey) || false) ||
        (quote.name?.trim().toLowerCase().includes(searchKey) || false)
      );
      const statusMatches = statusNum === -1 || quote.status === statusNum;

      const orderDate = new Date(quote.createdOn);
      const dateMatches = (fromDate && toDate) ? (orderDate >= fromDate && orderDate <= toDate) : true;

      return (searchKey ? searchMatches : true) && (statusNum !== -1 ? statusMatches : true) && (fromDate && toDate ? dateMatches : true);
    })
  } else {
    this.filteredQuotes = this.quoteListForSearch.slice(0, 200); 
  }

  this.quotes$ = of(this.filteredQuotes); 
  this.emptySearch = this.filteredQuotes.length === 0;
  if(!onInitSearch && this.isFullSearchEnabled()){
  this.quickSearchYeilds=true;
  }
  if(this.emptySearch && (onInitSearch === false || onInitSearch == null)){
   this.fullSearch() 
  }
}


showError(error:String){
  alert(error);

}
showComment(comment:String){
  alert(comment);
}
  
  // getQuotes(){
  //   // let fromDate:any;
  //   // let toDate:any;
  //   // fromDate = (new Date(this.fromDate) as Date)
  //   // fromDate.setUTCHours(0, 0, 0, 0)
  //   // toDate = (new Date(this.toDate) as Date)
  //   // toDate.setUTCHours(23, 59, 59, 999);
  //   // let reqBody = {
  //   //   "quote":this.quoteF,
  //   //   "name":this.quoteF,
  //   //   "createdAfter":fromDate.toISOString(),
  //   //   "createdBefore":toDate.toISOString(),
  //   //   "status":parseInt(this.statusF)    
  //   // }
  //   let reqBody = {
  //     "quote":"",
  //     "name":"",
  //     "createdAfter":null,
  //     "createdBefore":null,
  //     "status":-1    
  //   }
  //   this.endpointService.getQuotes(reqBody).subscribe((data)=>{
  //     this.quoteListForSearch=data;
  //     this.searchQuotes();
  //   })
  //   // if(this.fromDate==null){
  //   //   fromDate=null;
  //   //   reqBody.createdAfter=null;
     
  //   // }
  //   // if (this.toDate==null){
  //   //   toDate=null;
  //   //   reqBody.createdBefore=null;
  //   // }
    
  //   // this.quotes$ =this.endpointService.getQuotes(reqBody);
  // }
  navigateToQuoteDetails(quote:Quote) {
    if (!(this.getStatus(quote) == 'Error' || this.getStatus(quote) == 'Drafted')){
    const navigationExtras = {
      state: {
        data:{
          quote: quote
        }
      }
    };
    this.router.navigate(['/admin/quote-details',quote.quoteId], navigationExtras);
  }
  }
  getStatus(quote:Quote){
    const statusNum:number = quote.status;
  return eStatusM[statusNum as keyof typeof eStatusM];
  }
  reQuote(quote:Quote){
    delete quote.Id;
    quote.status = 1;
    this.endpointService.putCutWrightData(quote).subscribe(data => {
      //this.getQuotes()
      this.ngOnInit()
    })
  }

  getAmount(totalinvoiceamount:string){
    return parseFloat(totalinvoiceamount).toFixed(2);
  }
  getFormattedDateTime(incomingDate:string): string {
    this.dateTimeString= incomingDate;
    const date = new Date(this.dateTimeString);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
  }
}
