import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order, Quote, formattedDeliveryWindow } from 'src/app/models/cut-wrights.model';
import { DataService } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-print-job-label',
  templateUrl: './print-job-label.component.html',
  styleUrls: ['./print-job-label.component.scss']
})
export class PrintJobLabelComponent implements AfterViewInit {
  orderId= ""
  order!:Order;
  quote!:Quote;
  detailsFetched = false
  quoteSubscription: Subscription;
  orderSubscription!: Subscription;
  constructor(private metadataService: MetadataService,public epService:EndpointApiService, private route:ActivatedRoute,private dataService: DataService){
    this.orderId = this.route.snapshot.paramMap.get('orderId') as string;
    this.quoteSubscription=this.epService.getOrder(this.orderId,true).subscribe(data => {
      this.order=data
      this.orderSubscription= this.epService.getQuote(this.order.quoteId).subscribe(data =>{
        this.quote = data
        this.detailsFetched = true
      })
    })
    
    this.dataService.setHeaderFooterFlag(true);
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  ngAfterViewInit(): void {
    const header: any = document.getElementsByClassName('website-banner');
    const footer = document.getElementById('website-footer');
    if(header[0] && footer) {
      header[0].style.display = "none";
      footer.style.display = "none";
    }
  }
  getDeliveryTime(window:any){
    return formattedDeliveryWindow[window];
  }
  ngOnDestroy() {
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe(); 
    }
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe(); 
    }
  }

}
