<section class="banner-pages banner-bg-faq" role="banner">
    <div class="container">
        <div class="col-md-10 col-md-offset-1">
            <div class="banner-pages-text text-left">
<h1>Frequently Asked Questions</h1>
            </div><!-- banner text ends -->
        </div>
    </div>
</section>

<div class="page-container">
    <section id="faq" class="faq no-padding">
        <div class="container">
            <div class="row no-gutter">
                <div class="col-md-12 col-sm-12 faq-box text-left">
                    <p>
                        </p><div class="faq-question">Q: How can I get prices for the panels I require?</div>
                        <div class="faq-answer">A: Whichever way is easiest for you, you can use our automated online quoting system, email, phone or come in to see us and we can quote you there and then.</div>

                        <div class="faq-question">Q: What are you lead times?</div>
                        <div class="faq-answer">A: Typically, 3 -4 days for most orders but if you need something sooner just ask.</div>

                        <div class="faq-question">Q: Do you have a minimum order quantity?</div>
                        <div class="faq-answer">A: You may order as little as one panel providing we have an off cut, usually most minimum orders are one sheet of the chosen material.</div>

                        <div class="faq-question">Q: How accurately can you cut?</div>
                        <div class="faq-answer">
                            A: Our machines are capable of cutting to an accuracy of 0.1mm +/-<br>
                            In simple terms we would happily replace any panel that is more than 0.3mm different from your requested size, quibble free. <a [routerLink]="['/cnc-cutting']">Click here</a> for more information.
                        </div>

                        <div class="faq-question">Q: Can you cut shaped parts?</div>
                        <div class="faq-answer">A: We can, please see our <a [routerLink]="['/cnc-drilling-service']">drilling page</a> for information on this.</div>

                        <div class="faq-question">Q: Do you provide a drilling service?</div>
                        <div class="faq-answer">A: Yes, we can drill the faces and ends of panels. Please see our <a [routerLink]="['/cnc-drilling-service']">drilling page</a> for information on this.</div>

                        <div class="faq-question">Q: Can’t find the material you are looking for on our website?</div>
                        <div class="faq-answer">A: Please <a id="" [routerLink]="['/contact-us']">get in touch</a> its unlikely that we won’t have a suitable material for your application.</div>

                        <div class="faq-question">Q: Do you provide samples?</div>
                        <div class="faq-answer">A: Yes, we have a comprehensive stock of pre edged samples available from our Sample Shop. Please visit <a href="https://cutwrights.shop/">cutwrights.shop</a> or get in touch for more info.</div>

                        <div class="faq-question">Q: Do you offer a delivery service?</div>
                        <div class="faq-answer">A: Yes we do, using our own fleet of vehicles we can offer a reliable personal service. <a [routerLink]="['/delivery']">Click here</a> for more information.</div>

                        <div class="faq-question">Q:  Can I have the Off Cuts?</div>
                        <div class="faq-answer">A:  Yes, just select “Include Off Cuts” when placing your order and we will include them. We consider an off cut to be anything more than 0.3m2, with minimum dimensions of 500mm in length x 70mm in Width.</div>

                        <div class="faq-question">Q: Can I view my cutting patterns?</div>
                        <div class="faq-answer">A: Yes, your cutting patterns are available to view with every quotation you receive. You can use these to optimise the boards that you are buying to reduce waste and save money.</div>
                    <p></p>
                </div>
            </div>
        </div>
    </section><!-- faq -->
</div>
