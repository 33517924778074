<!-- <div *ngIf="isLoading" class="loader-section">
   <app-loader></app-loader>
</div> -->
<div class="panels-list quote-panel" id="panelsList">
   <form [formGroup]="form" class="quote-form w-100">
      <div>
<div *ngIf="!submitted" class="guest-quote-menu sub-menu">
         <div class="form-job-name">
          
            <!-- <div class="errorText text-danger mt-1"> 
            {{errorText}}
            </div> -->
                  <div id="enter-job-name">
               <div id="your-job-name" class="bold-condensed">
                  Your job name:
               </div>
               <div class="form">
                  <span class="form-body">
                     <div class="item mandatory">
                        <span class="item-body">
                           <div class="label hidden-label">
                              <label for="ctl22_ctl00_txtName" id="ctl22_ctl00_lblName">
                                 Your job name
                              </label>
                           </div>
                           <div class="job_i">
                              <input style="height: 32px; width: 320px;" type="text" formControlName="name" name="Width" autocomplete="off" maxlength="36">
                              <div style="margin-left: 0px; margin-top: -1px;" *ngIf="checkForRequiredError('name',0)" class="text-danger" ngb-form-validation>
                                 Job name is required
                              </div>
                              <div style="margin-left: 0px; margin-top: -1px;" *ngIf="checkForMaxName()" class="text-danger" ngb-form-validation>
                                 Name has exceeded the limit of 36 characters.
                              </div>
                           </div>
                        </span>
                     </div>
                  </span>
               </div>
            </div>
            <div id="help" style="padding-top: 20px;">
               <div id="text" class="bold-condensed">
                  If you need any help creating your cutting list please call us on
               </div>
               <div id="phone-number" class="bold-condensed">
                  0208 755 4829
               </div>
            </div>
         </div>
         
         
         <span [hidden]="(!content_fetched) || submitted" [class]="(isQuote) ? 'selected board-btn': ''">
            <span class="select-board-edging bold-condensed item">
               
                  <a (click)="isQuote = true;">
                     Select board + edging
                  </a>
               
            </span>
         </span>
         <span [hidden]="(!content_fetched) || submitted" [class]="(!isQuote) ? 'selected shaker-btn': ''">
            <span class="bold-condensed item">
               
                  <a (click)="isQuote = false">
                     Shaker Doors and Drawer Fronts
                  </a>
               
            </span>
         </span>
         <div [hidden]="(!content_fetched) || submitted" class="buttons">
            <a id="ctl22_btnPrintYourCuttingList" class="link-button cutting-list-print-button"
              >Print your cutting list</a>
         </div>
      </div>
      <div *ngIf="!isQuote" [hidden]="(!content_fetched) || submitted">
         <app-shaker-door [shakers]="getShakers()"></app-shaker-door>
      </div>

      <div *ngIf="isLoading" class="loader-section">
         <app-loader></app-loader>
      </div>
      <div *ngIf="isQuote" [hidden]="((!content_fetched) || submitted) || isLoading">
         <div formArrayName="formArray" class="mt-5">
            <table class="table table-striped table-condensed" style="width: 100%;">
               <thead>
                  <tr class="header-line bold">
                     <th></th>
                     <th class="line-number">#</th>
                     <th class="main-headers material">MATERIAL</th>
                     <th class="main-headers" colspan="3">MEASUREMENTS
                        <span id="lower-case">
                           (mm)
                        </span>
                     </th>
                     <th class="main-headers" colspan="4">EDGING</th>
                     <th class="main-headers part-details" colspan="3">PART DETAILS</th>
                     <!--<th class="main-headers drilling-header">DRILLING</th>-->
                  </tr>
                  <tr class="second-header-line roman">
                     <th></th>
                     <th colspan="2"></th>
                     <th class="measurements-column">Length</th>
                     <th class="measurements-column">Width</th>
                     <th class="measurements-column">Qty</th>
                     <th class="edging-column">L1</th>
                     <th class="edging-column">L2</th>
                     <th class="edging-column">W1</th>
                     <th class="edging-column">W2</th>
                     <th class="priming-column">Priming</th>
                     <th class="part-details">Name</th>
                     <th class="part-details">Weight (kg)</th>
                  </tr>
               </thead>
               <tbody class="roman">
                  <tr *ngFor="let group of formArray.controls; let i = index" [formGroupName]="i" (click)="selectRowIndex(i)">
                     <td>
                        <!-- <img class="error ng-hide" src="/Images/Icons/error.png" data-ng-show="part.errors.length >
                      0" title="W"> -->
                      <div>
                      <img style="width: 20px;height: 20px;"  src="../../../assets/images/warning.png" *ngIf="checkWarning(i)" [title]="length_warnings[i] ? length_warnings[i] : ''">
                     </div>
                     </td>

                     <td class="line-number">
                        <div class="line-number-text ng-binding">
                           <span style="font-size: 15px;">{{i+1}}</span>
                        </div>
                        <!-- <div class="triangles">
                   <img class="error ng-hide" src="/Images/Icons/error.png" data-ng-show="part.errors.length >
                      0" title="">
                      <img class="warning ng-hide" src="/Images/Icons/warning-large.png" data-ng-show="part.warnings.length >
                         0" title="">
                      </div> -->
                     </td>
                     <td class="material">
                        <input class="m-width"    formControlName="materialDescription" type="text"  [typeahead]="dataSource"
                               typeaheadOptionField="desc"   [typeaheadMinLength]="1" 
                               [isAnimated]="true" name="Material Code"  (typeaheadOnSelect)="onSelect($event,i)" autocomplete="off"
                               (focus)="setFormFocus(true)" (blur)="typeaheadEdgesValidator('materialDescription', i);setFormFocus(false)" 
                               [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="40" [typeaheadOptionsLimit]="1000" (keydown.enter)="onEnterPressed($event,i,'materialDescription')">
                     
                        <div *ngIf="checkForError('materialDescription',i)" ngb-form-validation>
                           <div *ngIf="checkForRequiredError('materialDescription',i)" class="text-danger" style="font-weight: bold;">
                              Material is required
                           </div>
                           <div *ngIf="checkForTypeAheadError('materialDescription',i)" class="text-danger" style="font-weight: bold;">
                              Unknown material code
                           </div>
                        </div>
                     </td>
                     <td class="mesurement"><input  type="number" step="1"  formControlName="length" (focus)="setFormFocus(true)" (blur)="checkForRequiredEdgingError('length',i);setFormFocus(false);typeaheadEdgesValidator('l2',i);typeaheadEdgesValidator('w2',i);calculateWeight(i);" (keydown.enter)="onEnterPressed($event,i,'length');calculateWeight(i);" maxlength="35" onkeydown="if(event.key === ' ') event.preventDefault();"/>
                        <div *ngIf="!newValidateLW(i)" ngb-form-validation>
                           <div  class="text-danger" style="font-weight: bold;">
                              {{this.length_errors[i]}}
                           </div>
                           <!-- <div *ngIf="checkForSError('length',i,'minLength')" class="text-danger" style="font-weight: bold;">
                              minimum panel size must be at least 60mm X 10mm
                           </div> -->
                           <!-- <div *ngIf="checkForSError('length',i,'min')" class="text-danger" style="font-weight: bold;">
                              minimum panel size must be at least 60mm X 10mm
                           </div> -->
                        </div>
                     </td>
                     <td class="mesurement"><input type="number" step="1"  (keydown.enter)="onEnterPressed($event,i,'width');calculateWeight(i);" formControlName="width" name="Width" autocomplete="off"
                        (focus)="setFormFocus(true)" (blur)="checkForRequiredEdgingError('width',i);setFormFocus(false);typeaheadEdgesValidator('l2',i);typeaheadEdgesValidator('w2',i);calculateWeight(i);" maxlength="35" onkeydown="if(event.key === ' ') event.preventDefault();">
                        
                        <div *ngIf="!newValidateLW(i)" ngb-form-validation>
                           <div  class="text-danger" style="font-weight: bold;">
                              {{this.width_errors[i]}}
                           </div>
                           <!-- <div *ngIf="checkForSError('width',i,'minWidth')" class="text-danger" style="font-weight: bold;">
                              minimum panel size must be at least 60mm X 10mm
                           </div> -->
                           <!-- <div *ngIf="checkForSError('width',i,'min')" class="text-danger" style="font-weight: bold;">
                              minimum panel size must be at least 60mm X 10mm
                           </div> -->
                        </div>                       
                     </td>
                     <td class="mesurement"><input type="number" step="1" formControlName="quantity" pattern="\d+" name="Quantity" (keydown.enter)="onEnterPressed($event,i,'quantity');calculateWeight(i)"
                           autocomplete="off" maxlength="35" (focus)="setFormFocus(true)" (blur)="setFormFocus(false);calculateWeight(i)" onkeydown="if(event.key === ' ') event.preventDefault();">
                           <div *ngIf="checkQuantity('quantity',i)" class="text-warning" style="font-weight: bold;">
                              Quantity is unusually high.
                           </div>
                        <div *ngIf="checkForError('quantity',i)" ngb-form-validation>
                            <div *ngIf="checkForRequiredError('quantity',i)" class="text-danger" style="font-weight: bold;">
                              please specify quantity.
                           </div>
                           <div *ngIf="checkForSError('quantity',i,'min')" class="text-danger" style="font-weight: bold;">
                              Invalid quantity.
                           </div>
                           
                           
                        </div>
                     </td>
                     <!-- <td><input  type="text" name="L1 Code" copy-edging-on-enter="" autocomplete="off"></td> -->
                     <td><input #inputBox1 type="text" formControlName="l1Description" name="L1 code" [typeaheadMinLength]="0" [typeahead]="getFD('l1',i)" typeaheadOptionField="description"
                        typeahead-editable="true" class="form-control" autocomplete="off" [typeaheadSelectFirstItem]=false [typeaheadIsFirstItemActive]=false focusFirst='false' (keydown.enter)="onEnterPressed($event,i,'l1Description');onEnterPressed($event,i,'l1')" (typeaheadOnSelect)="onSelectE($event,i,'l1')"
                        (focus)="setFormFocus(true)" (blur)="typeaheadEdgesValidator('l1',i);setFormFocus(false)">
                        <div *ngIf="warningFlags[i]['0']" class="text-warning" style="font-weight: bold;">
                           The board does not match with edging.
                        </div>
                        
                        <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor60(i)=='lesswidth') && inputBox1.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                           An additional charge may apply to fix edging to edges less than 60mm.
                        </div>
                        <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor150(i)=='length'|| checkFor150(i)=='both' ) && inputBox1.value.trim() !== ''&& !checkMinimumEdgeDimensions(i)">
                           An additional charge may apply to fix edging to edges less than 150mm.
                        </div>
                        <div  class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="(checkForMaxWidthEdging(i)!='') && inputBox1.value.trim() !== ''">
                           {{checkForMaxWidthEdging(i)}}
                        </div>
                        <div class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="checkMinimumEdgeDimensions(i) && inputBox1.value.trim() !== ''">
                           150mm x 150mm is the minimum size possible to edge all edges.
                        </div>
                     </td>
                     <td><input #inputBox2 type="text" name="L2 Code" [typeaheadMinLength]="0" [typeahead]="getFD('l2',i)" typeahead-focus-first='false' typeaheadOptionField="description"
                           typeahead-editable="true" formControlName="l2Description" [typeaheadSelectFirstItem]="false" [typeaheadIsFirstItemActive]=false class="form-control" (keydown.enter)="onEnterPressed($event,i,'l2'); onEnterPressed($event,i,'l2Description');"  autocomplete="off" (typeaheadOnSelect)="onSelectE($event,i,'l2')"
                           (focus)="setFormFocus(true)" (blur)="typeaheadEdgesValidator('l2',i);setFormFocus(false)">
                      
                           <div *ngIf="warningFlags[i]['1']" class="text-warning" style="font-weight: bold;">
                              The board does not match with edging.
                           </div>
                           <div *ngIf="checkForRequiredEdgingError('width',i) && !checkMinimumEdgeDimensions(i)" class="text-danger"  ngb-form-validation style="font-weight: bold;" >
                              60mm is the minimum width for both long sides to have edging
                           </div>
                           <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor60(i)=='lesswidth') && inputBox2.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                              An additional charge may apply to fix edging to edges less than 60mm.
                           </div>
                           <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor150(i)=='length' || checkFor150(i)=='both' ) && inputBox2.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                              An additional charge may apply to fix edging to edges less than 150mm.
                           </div>
                           <div  class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="(checkForMaxWidthEdging(i)!='') && inputBox2.value.trim() !== ''">
                              {{checkForMaxWidthEdging(i)}}
                           </div>
                           <div class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="checkMinimumEdgeDimensions(i) && inputBox2.value.trim() !== ''">
                              150mm x 150mm is the minimum size possible to edge all edges.
                           </div>
                        
                     </td>
                     <!-- <td><input type="text" name="W1 Code" copy-edging-on-enter="" formControlName="w1" autocomplete="off">
                     <div *ngIf="checkForError('w1',i)" ngb-form-validation>
                        <div *ngIf="checkForRequiredError('w1',i)" class="text-danger">
                           The W1 code is required.
                        </div>
                     </div>   
                  </td>
                  <td><input type="text" name="W2 Code" copy-edging-on-enter="" formControlName="w2" autocomplete="off">
                     <div *ngIf="checkForError('w2',i)" ngb-form-validation>
                        <div *ngIf="checkForRequiredError('w2',i)" class="text-danger">
                           The W2 code is required.
                        </div>
                     </div> 
                  </td> -->
                  <td><input #inputBox3 type="text" formControlName="w1Description" name="W1 code" [typeaheadMinLength]="0" [typeahead]="getFD('w1',i)" typeaheadOptionField="description"
                     typeahead-editable="true" [typeaheadSelectFirstItem]="false" [typeaheadIsFirstItemActive]=false (typeaheadOnSelect)="onSelectE($event,i,'w1')" class="form-control" (keydown.enter)="onEnterPressed($event,i,'w1Description');onEnterPressed($event,i,'w1')"  autocomplete="off"
                     (focus)="setFormFocus(true)" (blur)="typeaheadEdgesValidator('w1',i);setFormFocus(false)">
                     <div *ngIf="warningFlags[i]['2']" class="text-warning" style="font-weight: bold;">
                       The board does not match with edging.
                     </div>
                     <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor60(i)=='lesslength') && inputBox3.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                        An additional charge may apply to fix edging to edges less than 60mm.
                     </div>
                     <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor150(i)=='width' || checkFor150(i)=='both' ) && inputBox3.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                        An additional charge may apply to fix edging to edges less than 150mm.
                     </div>
                     <div  class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="(checkForMaxLengthEdging(i)!='') && inputBox3.value.trim() !== ''">
                        {{checkForMaxLengthEdging(i)}}
                     </div>          
                     <div class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="checkMinimumEdgeDimensions(i) && inputBox3.value.trim() !== ''">
                        150mm x 150mm is the minimum size possible to edge all edges.
                     </div>           
                     
               </td>
                 
                     <td><input #inputBox4 type="text" name="w2 Code" [typeaheadMinLength]="0" [typeahead]="getFD('w2',i)" typeaheadOptionField="description"  (typeaheadOnSelect)="onSelectE($event,i,'w2')"
                           (focus)="setFormFocus(true)" (blur)="typeaheadEdgesValidator('w2',i);setFormFocus(false)" typeahead-editable="true" formControlName="w2Description" [typeaheadSelectFirstItem]="false" [typeaheadIsFirstItemActive]=false class="form-control" (keydown.enter)="onEnterPressed($event,i,'w2Description');onEnterPressed($event,i,'w2')"  autocomplete="off"
                           >
                           <div *ngIf="warningFlags[i]['3']" class="text-warning" style="font-weight: bold;">
                             The board does not match with edging.
                           </div>
                           <div *ngIf="checkForRequiredEdgingError('length',i)" class="text-danger" style="font-weight: bold;"  ngb-form-validation>
                              60mm is the minimum width for both long sides to have edging
                           </div>
                           <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="(checkFor60(i)=='lesslength') && inputBox4.value.trim() !== '' && !checkMinimumEdgeDimensions(i)">
                              An additional charge may apply to fix edging to edges less than 60mm.
                           </div>
                           <div style="color: #ffc107; width: 100%; font-weight: bold;" *ngIf="((checkFor150(i)=='width' || checkFor150(i)=='both') && !checkMinimumEdgeDimensions(i) ) && inputBox4.value.trim() !== ''">
                              An additional charge may apply to fix edging to edges less than 150mm.
                           </div>
                           <div  class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="(checkForMaxLengthEdging(i)!='') && inputBox4.value.trim() !== ''">
                              {{checkForMaxLengthEdging(i)}}
                           </div>
                           <div class="text-danger" style="width: 100%; font-weight: bold;" *ngIf="checkMinimumEdgeDimensions(i) && inputBox4.value.trim() !== ''">
                              150mm x 150mm is the minimum size possible to edge all edges.
                           </div>
                          
                     </td>
                     <td class="priming-column" style="width:1px"><input type="checkbox" formControlName="primed" name="Needs Priming">
                        
                     </td>
                     <td class="part-name-column" ng-hide="partPreviewVisible"  style="width:1px"><input ng-blur="updatePartLine()"
                           type="text" name="Part Name" formControlName="partComment" copy-on-enter=""
                           autocomplete="off" navigator="" click-anywhere-but-here="lineUnactivated()"
                           safe-on-focus="lineActivated()" char-limit="35" ng-focus="changePanelsListWidth()" (focus)="setFormFocus(true)"
                           (blur)="setFormFocus(false)"
                           ng-model="part.name" class="ng-pristine ng-valid"
                           (keydown.enter)="onEnterPressed($event,i,'partComment')" maxlength="35" style="width:114px;">
                           <div *ngIf="checkForError('partComment',i)" ngb-form-validation>
                           <div *ngIf="checkForSError('partComment',i,'commaNotAllowed')" class="text-danger" style="font-weight: bold;" ngb-form-validation>
                           ',' is not allowed
                           </div>
                           <div *ngIf="checkForSError('partComment',i,'maxlength')" class="text-danger" style="font-weight: bold;" ngb-form-validation>
                              Only 35 charecters are allowed
                              </div>
                           </div>
                        </td>
                     <td class="weight-column" ng-hide="partPreviewVisible"><input type="text" formControlName="weight"
                           readonly="" navigator="" click-anywhere-but-here="lineUnactivated()"
                           safe-on-focus="lineActivated()" ng-focus="changePanelsListWidth()" ng-model="part.weight"
                           class="ng-pristine ng-valid" (keydown.enter)="onEnterPressed($event,i,'weight')" style="width:73px;"></td>
                     <!--<td class="drilling-button" ng-hide="partPreviewVisible"><input type="button"
                           class="cutting-list-buttons thin-condensed" value="ADD" ng-click="showPartPreview()"
                           click-anywhere-but-here="lineUnactivated()"></td>-->
                     <td><button name="Delete" type="button" (click)="deletePart(i)" class="delete">x</button></td>
                  </tr>
               </tbody>
            </table>

         </div>
         <div class="order-instructions" ng-hide="partPreviewVisible">
            <div class="next buttons more-rows">
               <div>
                  <button class=" add-btn cutting-list-buttons add-more-lines bold-condensed" type="button"
                     (click)="addRows()">Add More Rows(+10)</button>
               </div>
               <div>
                  <button *ngIf="isFormFocused" class=" add-btn cutting-list-buttons add-more-lines bold-condensed" style="margin-left: 3px;" type="button"
                     (click)="addRowAbove()">Insert Row Above</button>
               </div>
   
            </div>
            <div class="full-width">
               Special order instructions for Cutwrights
            </div>
            <div class="full-width">
               (if not required please leave blank)
            </div>
            <textarea formControlName="comments" class="c_text ng-pristine ng-valid" [placeholder]="commentText">
         </textarea>
         </div>
         


         <!-- <div [formGrup]="cForm" class="order-summary roman">
            <h3 class="bold-condensed">
               Order Summary
            </h3>
            <div class="category">
               Parts
               <div formControlName="parts" class="value">
                  0
               </div>
            </div>
            <div class="category">
               Shaker doors
               <div formControlName="shakerDoors" class="value">
                  1
               </div>
            </div>
            <div class="category">
               Drawers
               <div formControlName="drawers" class="value">
                  1
               </div>
            </div>
            <div class="category weight">
               Total weight
               <div class="value">
                  6.65kgs
               </div>
               <div id="below">
                  (excluding offcuts)
               </div>
            </div> -->
         <!-- </div> -->
         
      </div>
      <div class="ord-s" [hidden]="submitted">
         <form  [formGroup]="cForm">
             <h3 style="font-size: 2rem;">Order Summary</h3>
             <div class="ord-s-feilds">
                 <div class="login-labels">
                     <label for="parts">Parts:</label>
                 </div>
                 <div class="">
                     <input formControlName="parts" id="parts" class="login-inp" name="parts"
                         autocomplete="off">
                 </div>
                 <div class="login-labels">
                     <label for="shaker-doors">Shaker doors:</label>
                 </div>
                 <div class="">
                     <input class="login-inp" formControlName="shakerDoors" id="shaker-doors"  name="shaker-doors" autocomplete="off">
                 </div>
                 <div class="login-labels">
                     <label for="drawers">Drawer Fronts :</label>
                 </div>
                 <div class="os">
                     <input class="login-inp" formControlName="drawers"  id="drawers" name="drawers" autocomplete="off">
                 </div>
                 <div class="login-labels">
                     <label for="total-weight">
                         Totalweight
                         <span>
                             (excluding offcuts) :
                         </span>
                     </label>
                 </div>
                 <div class="">
                     <input class="login-inp" formControlName="weight"  id="total-weight"
                         name="total-weight" autocomplete="off">
                 </div>

                 
             </div>
         </form>
     </div>
    
      <div [hidden]="(!content_fetched) || submitted" class="pad-50">
         <button type="button" style="background-color: #FBBB01; color: black;"
         class="get-quote-btn-enabled cutting-list-buttons"
          (click)="getQuote()">Next</button>
         <div    *ngIf="isQuote"class="file-add cutting-list-buttons add-more-lines bold-condensed">
         <label for="magic-list" style="padding: 0px; color: white; font-weight: unset; font-size: 15px;">MAGIC LIST</label>
         <input id="magic-list" style="visibility:hidden;" type="file" accept=".xlsx, .xls" name="upload" (input)="readExcelFile($event)"  />
          </div>
      </div>
      <div  *ngIf="isQuote" [hidden]="(!content_fetched) || submitted" class="template-text">
         <span>Please download the template by clicking download template button and populate your cuttinglist using Microsoft excel or Google sheet and upload by clicking magic list button. We will import your file and populate your list into the cuttinglist.</span>
         <a [href]="mTemplateUrl">Download Template</a>
          </div>
   </div>
      <div *ngIf="submitted">
         <app-quote-result [quote]="quote"></app-quote-result>
      </div>
      <ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
         <ul class="custom-list-group">
           <li class="custom-list-group-item"
               *ngFor="let match of matches"
               [class.active]="typeaheadTemplateMethods.isActive(match)"
               (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
               (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
             {{ match.item }}
           </li>
         </ul>
       </ng-template>
   </form>
</div>
<ng-template #overflowPopup>
   <div class="modal-content">
         <div class="modal-header">
             <h5 class="modal-title"><b>{{modalHeader}}</b></h5>
         </div>
         <div class="modal-body">
             <p style="color: black;">{{ modalMessage }}</p>
             </div>
         <div class="modal-footer">
             <button type="button" class="modal-button" (click)="refreshPage()">Ok</button>
         </div>
   </div>
</ng-template>