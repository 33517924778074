import { inject, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Observable, Subject, catchError, firstValueFrom, forkJoin, lastValueFrom, map, of } from "rxjs";
import { EndpointApiService } from "./endpoint-api.service";
import { User } from "../models/user.model";
import { addDays, isWeekend, isSameDay } from 'date-fns';
import { isPlatformBrowser } from "@angular/common";
export interface Impersonate{
  user :User
  admin:User
}
@Injectable({
    providedIn: 'root'
  })
  export class DataService {
    private dataSubject = new BehaviorSubject<boolean>(false);
    private panelOffcutSubject = new BehaviorSubject<boolean>(true);
    impersonateSubject = new Subject<Impersonate>();
    constructor(@Inject(PLATFORM_ID) private platformId: Object,private epService:EndpointApiService) {}
    data:any;

    topMaterial:any;  // For materials
    topEdge:any      // For edges
    topBoard:any     // For boards
    topTag:any 

    isWeekend(date: Date): boolean {
      return isWeekend(date);
    }
    isBankHoliday(date: Date, bankHolidays: Date[]): boolean {
      return bankHolidays.some(holiday => isSameDay(date, holiday));
    }
    addDaysWithWeekendHandling(date: Date, days: number, bankHolidays: Date[]): Date {
      do{
        if(!this.isWeekend(date) && !this.isBankHoliday(date,bankHolidays)){          
          days--;
        }
        date=addDays(date,1);
      }while (days>0 || this.isWeekend(date) || this.isBankHoliday(date,bankHolidays) )
      
      return date;
    }

    setHeaderFooterFlag(data: boolean): void {
      this.dataSubject.next(data);
    }
  
    getHeaderFooterFlag(): Observable<boolean> {
      return this.dataSubject.asObservable();
    }

    setPanelAndOffcut(data:boolean):void{
      this.panelOffcutSubject.next(data);
    }

    getPanelAndOffcut(): Observable<boolean>{
      return this.panelOffcutSubject.asObservable();
    }


  emitImpersonateFlow(data:Impersonate){
    this.impersonateSubject.next(data);
  }
  closeImpersonateFlow(){
    this.impersonateSubject.complete();
  }

  findTopId(data: { [key: string]: { id: string } }): any {
    const sortedEntries = Object.entries(data).sort(([, a], [, b]) => {
        return a.id > b.id ? -1 : 1; // Compare the 'id'
    });

    return sortedEntries[0][1]; // Return the first entry's object (latest one)
}

findKey(keyword: string, prerenderedData:any): string | null {
  const apiEndpoints: { [key: string]: string } = {
    tags: 'api/tags',
    boards: 'api/boards',
    edges: 'api/edges',
    materialsAll: 'api/materialsAll',
  };

  //API endpoint associated with the given keyword
  const apiEndpoint = apiEndpoints[keyword];


  // Loop through all keys (parent IDs)
  for (const parentId in prerenderedData) {
    if (prerenderedData.hasOwnProperty(parentId)) {
      const parentData = prerenderedData[parentId];

      if (parentData && typeof parentData.u === 'string') {
        if (parentData.u.includes(apiEndpoint)) {
          return parentId; 
        }
      }
    }
  }

  return null;
}

 // Method to fetch prerendered data
 getPrerenderedData() {
  if (isPlatformBrowser(this.platformId)) {
    const scriptTag = document.getElementById('ng-state') as HTMLScriptElement;
    if (scriptTag) {
        const prerenderedData = JSON.parse(scriptTag.textContent || '{}');
  
        const tagsKey  = this.findKey("tags",prerenderedData); 
        const  boardsKey=  this.findKey("boards",prerenderedData); 
        const edgesKey =  this.findKey("edges",prerenderedData); 
        const materialsKey =  this.findKey("materialsAll",prerenderedData); 

        this.data = [
          materialsKey? (prerenderedData[materialsKey]?.b || null):null,
          edgesKey? (prerenderedData[edgesKey]?.b || null):null, 
          boardsKey? (prerenderedData[boardsKey]?.b || null):null, 
          tagsKey? (prerenderedData[tagsKey]?.b || null):null,
      ];
      return true
      }
      return false;
  }
  return false;
}

  async fetchData() {
    if (!this.getPrerenderedData()) {
    let data = await lastValueFrom(forkJoin([
      this.epService.getMaterialsDataAzure(),
      this.epService.getEdgesDataAzure(),
      this.epService.getBoardsDataAzure(),
      this.epService.getMaterialsTagsDataAzure()]))

    
    this.data = data;
    }
    this.topMaterial = this.findTopId(this.data[0]);  // For materials
    this.topEdge = this.findTopId(this.data[1]);      // For edges
    this.topBoard = this.findTopId(this.data[2]);     // For boards
    this.topTag = this.findTopId(this.data[3]); 
  
  if (isPlatformBrowser(this.platformId)) {
    try {
      //Error Handling, so failure will not stop app from loading.
      await firstValueFrom(
        forkJoin([
          this.deltaFunctionMaterials().pipe(catchError(error => {
            // console.error('Failed to fetch delta materials:', error);
            return of(null); // Return fallback value
          })),
          this.deltaFunctionEdges().pipe(catchError(error => {
            //console.error('Failed to fetch delta edges:', error);
            return of(null); // Return fallback value
          })),
          this.deltaFunctionBoards().pipe(catchError(error => {
            //console.error('Failed to fetch delta boards:', error);
            return of(null); // Return a fallback value
          })),
          this.deltaFunctionTags().pipe(catchError(error => {
            //console.error('Failed to fetch delta tags:', error);
            return of(null); // Return a fallback value
          }))
        ])
      );
    } catch (error) {
      //console.error('Delta function error:', error);
    }
  }
}
  deltaFunctionMaterials() {
    
    let materials: any = this.data[0]; // OLD Materials imported
    return this.epService.getMaterialDelta(this.topMaterial.id).pipe( // Delta API
      map((delta: any) => {
        if (delta) {
          // Loop through the delta and update materials based on the 'code' property
          Object.keys(delta).forEach((deltaKey) => {
            let deltaItem = delta[deltaKey];

            // Find if the delta item exists in the current materials by 'code'
            const existingMaterialKey = Object.keys(materials).find(key => materials[key].code === deltaItem.code);

            if (existingMaterialKey) {
              // Replace 
              materials[existingMaterialKey] = deltaItem;
            } else {
              //or add the new delta item
              materials[deltaKey] = deltaItem;
            }
          });
          
          // Update the data
          this.data[0] = materials;
          return materials;
        }
        return this.data[0];
      })
    );
  }

  deltaFunctionEdges() {
  let edges: any = this.data[1]; 
  return this.epService.getEdgeDelta(this.topEdge.id).pipe(
    map((delta: any) => {
      if (delta) {
        Object.keys(delta).forEach((deltaKey) => {
          let deltaItem = delta[deltaKey];

          const existingEdgeKey = Object.keys(edges).find(key => {
            return edges.hasOwnProperty(key) && edges[key].code === deltaItem.code;
          });

          if (existingEdgeKey) {
            edges[existingEdgeKey] = deltaItem;
          } else {
            edges[deltaKey] = deltaItem;
          }
        });
        this.data[1] = edges;
        return edges;
      }
      return this.data[1];
    })
  );
  }

deltaFunctionBoards() {

  let boards: any = this.data[2]; 
  return this.epService.getBoardDelta(this.topBoard.id).pipe(
    map((delta: any) => {
      if (delta) {
        Object.keys(delta).forEach((deltaKey) => {
          let deltaItem = delta[deltaKey];

          const existingBoardKey = Object.keys(boards).find(key => {
            return boards.hasOwnProperty(key) && boards[key].code === deltaItem.code;
          });

          if (existingBoardKey) {
            boards[existingBoardKey] = deltaItem;
          } else {
            boards[deltaKey] = deltaItem;
          }
        });

        this.data[2] = boards;
        return boards;
      }
      return this.data[2];
    })
  );
}


deltaFunctionTags() {
  let tags: any = this.data[3]; 
  return this.epService.materialTagsDelta(this.topTag.id).pipe(
    map((delta: any) => {
      if (delta) {
        Object.keys(delta).forEach((deltaKey) => {
          let deltaItem = delta[deltaKey];
          const existingTagKey = Object.keys(tags).find(key => {
            return tags.hasOwnProperty(key) && tags[key].code === deltaItem.code;
          });

          if (existingTagKey) {
            tags[existingTagKey] = deltaItem;
          } else {
            tags[deltaKey] = deltaItem;
          }
        });
        this.data[3] = tags;
        return tags;
      }
      return this.data[3];
    })
  );
}

  }

export function initializeApp(appInitializer: DataService) {
    return () => appInitializer.fetchData();
  }