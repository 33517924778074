import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, map } from 'rxjs';  
import { QuotationDetails, QuotationModel, Quote, eStatusM, eStatusO } from 'src/app/models/cut-wrights.model';
import { Shaker,eShakerType, eShakerTypeM } from 'src/app/models/shaker.model';
import { User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { PaymentService } from 'src/app/service/payment-service';
import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { MetadataService } from 'src/app/service/metadata.service';
import { DataService, Impersonate } from 'src/app/service/data-service';
@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {
  quoteId:string;
  @ViewChild('template') shakerDoor!: TemplateRef<any>;
  @ViewChild('ExtraCostTemplate') extraCost!: TemplateRef<any>;
  quotation$: Observable<QuotationModel | undefined>;
  quote$:Observable<Quote>;
  nominalCode$!:Observable<any>;
  departmentCode$!:Observable<any>;
  designFile$ :Observable<string>
  modalRef!:any
  quote:any
  imageSrc = "";
  focussedElement = "";
  sformGroup!:FormGroup;
  extraCostForm!:FormGroup;
  user!:User;
  isOrder = false;
  isResolved = false;
  closeAlert = true;
  isAdmin = false;
  materials:any;
  edges:any;  
  materialArray:any=[];
  edgeArray:any=[];
  impersonate:boolean=false;
  isExtraEdit:boolean=false;
  editExtraCode:string='';
  constructor(private _dataService :DataService,private metadataService: MetadataService,private apiService: EndpointApiService, private router: Router,private route: ActivatedRoute,private paymentService: PaymentService,private modalService: BsModalService, private formBuilder: FormBuilder){
    const currentUrl = this.route.snapshot.url.join('/');
  
  if (currentUrl.includes('order')) {
    this.isOrder = true
  }
    this.quoteId = this.route.snapshot.paramMap.get('quoteId') as string;
    const token = localStorage.getItem('token');
  const userString = localStorage.getItem('user');
  if (token) {
    this.user = JSON.parse(userString as string)
    this.isAdmin = this.user.roles.includes("admin")?true:false;
  }
    this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
    this.quote$ = this.apiService.getQuote(this.quoteId);
    this.quote$.subscribe(data => {
      this.quote = data;
      if(data.comment.includes("[RESOLVED]")){
        this.isResolved=true;
      }
      this.quote.totalinvoiceamount= apiService.truncateDecimal(data.totalinvoiceamount)
      //this.quote.totalnetamount= apiService.truncateDecimal(this.quotation$.totalnetamount)
      this.quotation$ = this.apiService.getQuotationDetails(this.quoteId).pipe(
        map((quotation) => {
          if (quotation) {
            quotation.totalnetamount =quotation.totalnetamount;
            quotation.totalvatamount = quotation.totalvatamount;
            quotation.totalinvoiceamount= quotation.totalinvoiceamount;
            for (let index = 0; index < quotation.details.length; index++) {
              quotation.details[index].netprice= quotation.details[index].netprice;
              quotation.details[index].total= quotation.details[index].total;
              quotation.details[index].unitprice= quotation.details[index].unitprice;
              quotation.details[index].vat= quotation.details[index].vat;              
            }
           
          }
          return quotation;
        })
      );
  

      
    })
    this.designFile$ = this.apiService.getDownloadUrl(this.quoteId);
    this.extraCostForm = this.formBuilder.group({
      code:["Extra Items"],
      description:[],
      unitPrice: [null, [Validators.required, Validators.min(0)]],
      departmentCode: [null, Validators.required],
      nominalCode: [null, Validators.required],
    });
    this.nominalCode$ = this.apiService.getNominalCodes()
    this.departmentCode$ = this.apiService.getDepartmentCodes()

    this.apiService.getMaterialsDataAzure().subscribe((data:any)=>{
      this.materials=data;
      this.filterMaterials();      
    })

    this.apiService.getEdgesDataAzure().subscribe((data:any)=>{
      this.edges=data;
      this.filterEdges();
    })
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    if(localStorage.getItem('impersonate')=="true"){
      this.impersonate=true;
    }
  }
  
  goToQuotes(){
    if(this.isOrder){
      this.router.navigate([`/customer/order-list/${this.user.userId}`])
    } else{
      this.router.navigate([`/customer/my-quote/${this.user.userId}`])

    }
  }
  truncateValueQuantity(value: number): string {
    return this.apiService.truncateDecimalQuantity(value);
  }
  truncateValue(value: number): string {
    return this.apiService.truncateDecimal(value);
  }
  download_file(file:string){
    window.open(file, '_blank');
  }
  getWeight(quote:Quote){
    var weight = 0;
    quote.parts.forEach(part => {
      weight += Number(part.weight);
    })
    return weight.toFixed(2);
  }
  makePayment(amount:number) {
    if (this.user.roles.includes("admin"))
      {
        this.apiService.getOneCustomer(this.quote.customerId).subscribe((data:User)=>{
          localStorage.setItem('tempUser',JSON.stringify(data))
  
        })
      }
  
      this.router.navigate(['/customer/payment',this.quoteId])
    }
   

makeManualPayment(amount:number){
  let reqBody = {
    "QuoteId": this.quoteId,
    "Amount": amount,
    "IsPayment": true,
    "OrderId":"",
    "PaymentType": 0,
    "Comment": "manual payment",
    "CreatedOn" : (new Date()).toISOString()
  }
  this.apiService.makeManualPayment(reqBody).subscribe(data => {
    this.router.navigate([`/customer/payconfirm/${this.quoteId}`])
  })
}
openPopUp(quote:Quote,index:number){
  let shaker = quote.shakers[index];
  let hingesArray = this.formBuilder.array([]) as FormArray;
          let midRailsArray = this.formBuilder.array([]) as FormArray;
          let group = this.formBuilder.group({
            name: [shaker.name, Validators.required],
            height: [shaker.height, [Validators.required, Validators.max(2420)]],
            width: [shaker.width, [Validators.required, Validators.max(900)]],
            quantity: [shaker.quantity, Validators.required],
            primed: [shaker.primed],
            weight: [shaker.weight, Validators.required],
            type: [shaker.type],
            totalnumberofmidrails: [shaker.totalnumberofmidrails],
            midrails: midRailsArray,
            hingeholes35mm: [shaker.hingeholes35mm],
            useinsertahinges: [shaker.useinsertahinges],
            hingeside: [shaker.hingeside],
            totalnumberofhingeholes: [shaker.totalnumberofhingeholes],
            hinges: hingesArray,
            drillingdistance: [shaker.drillingdistance],
            // blumInserta: [shaker.blumInserta]  //removed by KD as dupicate variable wrt useinsertahinges
          })
          shaker.hinges.forEach(hinge => {
            hingesArray.push(this.formBuilder.group({
              y: [hinge.y]
            }))
          })
          shaker.midrails.forEach(rail => {
            midRailsArray.push(this.formBuilder.group({
              position: [rail.position, [Validators.min(90)]]
            }))
          });
  var template: TemplateRef<any> = this.shakerDoor;
  this.sformGroup = group;
  this.openModal(template)
}
close(){
  this.modalRef.hide()
}
getMidrailControls(formGroup: FormGroup) {
  return (formGroup.get('midrails') as FormArray).controls as FormGroup[]
}
getHingeControls(formGroup: FormGroup) {
  return (formGroup.get('hinges') as FormArray).controls as FormGroup[]
}
openModal(template: TemplateRef<any>): void {
  this.image_handler();
  this.sformGroup;
  this.modalRef = this.modalService.show(template, { class: 'shaker-door-modal', initialState: { iFormGroup: this.sformGroup, close: this.close } });
}
setFocus(elem :string){
  this.focussedElement = elem;
  this.image_handler()
}
resetFocus(elem :string){
  this.focussedElement = "";
  this.image_handler()
}
image_handler() { 
  if (this.sformGroup.get('width')?.valid && this.sformGroup.get('height')?.valid) {
    let value = this.sformGroup.value;
    let midrails = ""
    let hingeHoles = ""
    if (value.type == 0) {
      midrails = value.midrails?.reduce(((val: any, cVal: any, cIndex:any) => { if (cVal.position) { return val + (this.focussedElement == "m"+cIndex ? "T":"") + cVal.position + "," } return "" }), "") as string
      hingeHoles = value.hinges?.reduce(((val: any, cVal: any,cIndex:any) => { if (cVal.y) { return val + (this.focussedElement == "h"+cIndex ? "T":"") + cVal.y + "," } return "" }), "") as string
      if (midrails.endsWith(',')) {
        midrails = midrails.slice(0, -1); // Remove the last character
      }
      if (hingeHoles.endsWith(',')) {
        hingeHoles = hingeHoles.slice(0, -1); // Remove the last character
      }
    }
    let url = this.apiService.generateImageUrl(
      '22mmMoistureResistantMediteMDF',  // materialCode
      18,                               // thickness
      true,                               // showHandle
      value.type as number,               // type
      true,                               // hasGrain
      value.hingeside == "0" ? 'Left' : 'Right',  // hingeSide
      true,                               // rotate90Degrees
      midrails,                            // midrails
      hingeHoles ? hingeHoles : "",                             // hingeHoles
      value.drillingdistance as number,              // drillingDistance
      value.useinsertahinges as boolean,             //blumInserta New variable
      // value.blumInserta as boolean,                  // blumInserta
      parseInt(value.height as string),             // length
      parseInt(value.width as string),              // width

    );
    this.apiService.fetchImage(url).subscribe(data => {
      const uint8Array = new Uint8Array(data);
      const base64String = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));
      this.imageSrc = `data:image/png;base64,${base64String}`;
    })
  }
}
__doPostBack(s:string,s2:string){

}
getLinkName(shaker:Shaker){
  var l_name = ""
  if(shaker.totalnumberofhingeholes > 0){
    l_name += shaker.totalnumberofhingeholes +"Hinge hole"
  }
  if(shaker.totalnumberofmidrails > 0){
    l_name += shaker.totalnumberofmidrails +"Midrail"
  }

  return l_name;
}
resolve(){
  this.quote$.subscribe(data =>{
    delete data.Id
    data.status = 1;
    this.apiService.resolveQuote(this.quoteId).subscribe((data:any)=>{
      this.quote$ = this.apiService.getQuote(this.quoteId);
    })
  })
  
}
openExtraCost(){

  this.modalRef = this.modalService.show(this.extraCost, { class: 'shaker-door-modal', initialState: {close: this.close } });
}
editExtraCostItem(code: string) {
  this.apiService.getQuotationDetails(this.quoteId).subscribe((data: any) => {
    const matchingDetail = data.details.find((item: any) => item.code === code);
    this.editExtraCode=matchingDetail.code;
    if (matchingDetail) {
        this.extraCostForm.patchValue({
        description: matchingDetail.description,
        unitPrice: matchingDetail.unitprice,
        departmentCode: matchingDetail.departmentcode,
        nominalCode: matchingDetail.nominalcode
      });
    }
    const initialState = { close: this.close, data: matchingDetail };
    this.isExtraEdit=true;
    this.modalRef = this.modalService.show(this.extraCost, { class: 'shaker-door-modal', initialState: initialState });
  });
}



submitExtraCost(){
    if (this.isExtraEdit){
      this.isExtraEdit = false;
      this.close(); 
      // this.deleteItem(this.editExtraCode); 
      // setTimeout(() => {
        this.editECI(this.editExtraCode);
      // }, 500);
    } else {
      this.close();
      this.performPatchRequest();
    }
}

editECI(editExtraCode?:any) {
  let value = this.extraCostForm.value;
  value.code=editExtraCode
  this.apiService.editECI(value, this.quoteId).subscribe(()=> {
    this.quote$ = this.apiService.getQuote(this.quoteId);
    this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
  });
  
}

performPatchRequest() {
  let value = this.extraCostForm.value;
  this.apiService.patchQuotationDetails(value, this.quoteId).subscribe(data => {
    this.quote$ = this.apiService.getQuote(this.quoteId);
    this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
  });
}

sendEmail(){
  this.apiService.sendQuoteEmail(this.quoteId).subscribe(data => {
    //TODO - ADD A SUCESS BATCH email sent sucessfully
    this.closeAlert = false
  })
}
getStatus(quote:Quote){
  const statusNum:number = quote.status;
if(this.isOrder){
  return eStatusO[statusNum as keyof typeof eStatusO];
}  
return eStatusM[statusNum as keyof typeof eStatusM];
}
generatePDF() {
  const pdf = new (jsPDF as any)({
    orientation: 'portrait',
  });
  // pdf.setOrientation('landscape');
  let body:any[] = []
  this.quote$.subscribe(data =>{
    pdf.setFontSize(30);
  pdf.text('Cutwrights Limited', 10, 10);
  pdf.setFontSize(15);
  pdf.text(`Your cutting list reference: ${data.jobId}`, 10, 20);
  pdf.text(`Your job name:${data.name}`, 10, 30);  
  pdf.text(`Weight:${parseFloat(data.weight).toFixed(2)} kg`, 10, 40);
    if(data.parts.length > 0){
      data.parts.forEach((part,index) =>{
        body.push([index+1,part.materialDescription,part.length,part.width,part.quantity,part.l1Description,part.l2Description,part.w1Description,part.w2Description,part.operations,part.partComment,part.primed?"Yes":"No"])
      })
      pdf.text('Panels', 10, 60);
      pdf.setFontSize(9);
      pdf.autoTable({
        head: [["Part", "Material", "L" , "W", "Qty","L1","L2","W1","W2","Operations","Name","Priming"]],
        body: body,
        startY:70,
        margin: { top: 20 },
        theme: 'grid',
        styles: {
          textColor: [0, 0, 0], // Set text color to black (RGB: 0, 0, 0)
          lineColor: [0, 0, 0], // Set border color to black (RGB: 0, 0, 0)
        },
        headStyles: {
          fillColor: [255, 255, 255], // Set header background color to white (RGB: 255, 255, 255)
          textColor: [0, 0, 0], // Set header text color to black (RGB: 0, 0, 0)
          lineWidth: 0.2, // Set header border width
          lineColor: [0, 0, 0], // Set header border color to black (RGB: 0, 0, 0)
        },
        columnStyles: {
          0: { columnWidth: 'auto' },
          1: { columnWidth: 'auto' },
          2: { columnWidth: 'auto' },
          3: { columnWidth: 'auto' },
          4: { columnWidth: 'auto' },
          5: { columnWidth: 'auto' },
          6: { columnWidth: 'auto' },
          7: { columnWidth: 'auto' },
          8: { columnWidth: 'auto' },
          9: { columnWidth: 'auto' },
          10: { columnWidth: 'auto' },
          11: { columnWidth: 'auto' }
        },
      });
    } else {
      pdf.text('There ar no parts to display',8, pdf.autoTable.previous.finalY + 10);
    }
    
    body = []
    data.shakers.forEach((part,index) =>{
      body.push([index+1,eShakerTypeM[part.type as keyof typeof eShakerTypeM],part.height,part.width,part.quantity,this.getLinkName(part),part.name,part.primed?"Yes":"No"])
    })
    if(data.shakers.length > 0){
      pdf.setFontSize(15);
      pdf.text('Shaker Doors',10, pdf.autoTable.previous.finalY + 10);
      pdf.setFontSize(9);
    pdf.autoTable({
      head: [["Part", "Material", "H" , "W", "Qty","Operations","Name","Needs Priming"]],
      body: body,
      startY: pdf.autoTable.previous.finalY + 20,
      margin: { top: 20 },
      theme: 'grid',
      styles: {
        textColor: [0, 0, 0], // Set text color to black (RGB: 0, 0, 0)
        lineColor: [0, 0, 0], // Set border color to black (RGB: 0, 0, 0)
      },
      headStyles: {
        fillColor: [255, 255, 255], // Set header background color to white (RGB: 255, 255, 255)
        textColor: [0, 0, 0], // Set header text color to black (RGB: 0, 0, 0)
        lineWidth: 0.2, // Set header border width
        lineColor: [0, 0, 0], // Set header border color to black (RGB: 0, 0, 0)
      },
      columnStyles: {
        0: { columnWidth: 'auto' },
        1: { columnWidth: 'auto' },
        2: { columnWidth: 'auto' },
        3: { columnWidth: 'auto' },
        4: { columnWidth: 'auto' },
        5: { columnWidth: 'auto' },
        6: { columnWidth: 'auto' },
        7: { columnWidth: 'auto' },
        8: { columnWidth: 'auto' },
        9: { columnWidth: 'auto' },
        10: { columnWidth: 'auto' },
        11: { columnWidth: 'auto' }
      },
    });
    } else {
      pdf.text('There are no parts to display',8, pdf.autoTable.previous.finalY + 10);
    }
    window.open(pdf.output('bloburl', { filename: 'new-file.pdf' }), '_blank');
    });

}
getType(part:any){
  return eShakerType[part.type]
}
makeAChange() {
  const includeOffCuts = this.quote.includeOffCuts;
  const panelsNeedsLabelling = this.quote.panelsNeedsLabelling;

  if (this.user.roles.includes("admin")) {
    this.apiService.getOneCustomer(this.quote.customerId).subscribe((data: User) => {
      this.impersonateUser(data);

      // Wait for 1 second (adjust the delay time as needed)
      setTimeout(() => {
        this.continueAfterImpersonation(includeOffCuts, panelsNeedsLabelling);
      }, 1000);
    });
  } else {
    this.continueAfterImpersonation(includeOffCuts, panelsNeedsLabelling);
  }
}

continueAfterImpersonation(includeOffCuts: any, panelsNeedsLabelling: any) {
  this.apiService.mergeQuotes({
    "quoteId": [this.quoteId]
  }).subscribe((data: any) => {
    this.router.navigate([`/customer/get-quote`, data.quoteId], {
      queryParams: {
        includeOffCuts: includeOffCuts,
        panelsNeedsLabelling: panelsNeedsLabelling,
        oldQuoteId: this.quoteId
      }
    });
  });
}

impersonateUser(user:User): void {
  var data:Impersonate={
    user:user,
    admin:this.user
  }
  this._dataService.emitImpersonateFlow(data);
  //this.router.navigate(['/customer/get-quote/',this.quoteId])
}
getShakerDoorsC(){
  if(this.quote){
    let count = 0
    this.quote.shakers?.forEach((sha:Shaker) =>{
      if(sha.type == eShakerType.ShakerDoor){
        count+=sha.quantity;
      }
    })
    return count;
  }
   return 0; 
  }
  getShakerDrawerC(){
    if(this.quote){
      let count = 0
      this.quote.shakers?.forEach((sha:Shaker) =>{
        if(sha.type == eShakerType.ShakerDrawer || sha.type == eShakerType.PlainDrawer){
          count+=sha.quantity;
        }
      })
      return count;
    }
    return 0
    
  }
navigateToQuote(){
  window.open(this.router.createUrlTree(['customer/invoice-receipt',this.quoteId,"quote"], { relativeTo: this.router.routerState.root }).toString(), '_blank');
}

  getAmount(totalinvoiceamount:string){
    return parseFloat(totalinvoiceamount).toFixed(2);
  }
  filterMaterials() {
    for (const key in this.materials) {
      if (this.materials.hasOwnProperty(key)) {
        const materialObject: any = this.materials[key];
        const codes = [...materialObject.stock_codes];
        this.materialArray.push({ codes: codes, warningMessage: materialObject.warningmessage});
      }   
    }  
  }
  filterEdges(){
    for(const key in this.edges){
    
      if(this.edges.hasOwnProperty(key)){
        const edgeObject:any = this.edges[key];
        
        this.edgeArray.push({codes:edgeObject.code, edgeWarning:edgeObject.warning })
      }
    }
  }

  findWarningByCode(code: string) {
    
    for (const material of this.materialArray) {     
      if (material.codes.includes(code)) {
        return material.warningMessage;
      }
    }
    for(const edge of this.edgeArray){
      if(edge.codes.includes(code)){
        return edge.edgeWarning;
      }
    }
    return false;
  }
  deleteItem(code: string) {
    const requestBody = {
        code: code
    };

    this.apiService.removeQuotationDetails(requestBody, this.quoteId).subscribe((data: any) => {
        this.quote$ = this.apiService.getQuote(this.quoteId);
        this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
    });
  }

  checkIfDeletePossible(code:string){
    if(code.includes("EXTRA")){
      return true
    }
    else{
      return false
    }
  }
}



