import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSerializer } from "@angular/router";
import { CustomerRoutingModule } from "./customer/customer.routing.module";
import { AdminRoutingModule } from "./admin/admin.routing.module";
import { PageNotFoundComponent } from "./customer/page-not-found/page-not-found.component";
import { OldUrlsComponent } from "./customer/old-urls/old-urls.component";
const routes: Routes = [
  { path: 'new/ItemDetail/Board/:boardId', component: OldUrlsComponent },
  { path: "**", component: PageNotFoundComponent },
];
@NgModule({
  imports: [
    CustomerRoutingModule,
    AdminRoutingModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  providers: [
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
