import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
export interface ModalInitialState {
  address: string;
  city:string;
  postcode:string;
}
@Component({
  selector: 'app-delivery-address-pop-up',
  templateUrl: './delivery-address-pop-up.component.html',
  styleUrls: ['./delivery-address-pop-up.component.scss']
})
export class DeliveryAddressPopUpComponent {
  addressForm: FormGroup;
  address: string=""; 
  city: string=""; 
  postcode: string=""; 
  constructor(private apiService: EndpointApiService, private metadataService: MetadataService,public bsModalRef: BsModalRef,private formBuilder: FormBuilder) {
    this.addressForm = this.formBuilder.group({
      address: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });
    }
  ngOnInit(){
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.addressForm.get('address')?.setValue(this.address);
    this.addressForm.get('city')?.setValue(this.city);
    this.addressForm.get('postcode')?.setValue(this.postcode);
    }
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  submitForm() {
    if(this.addressForm.value){
    this.formSubmit.emit(this.addressForm.value);
    this.bsModalRef.hide();
    }
  }
  closePopup() {
    this.bsModalRef.hide(); 
  }
  checkPostCode():boolean {
    var postcode = this.addressForm?.get('postcode')?.value;
    if (postcode) {
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
    this.apiService.getPostCodeAzure(normalizedPostcode).subscribe((data: any) => {
      if (data["valid"] == false) {
        this.addressForm.get('postcode')?.setErrors({ 'invalid': true });
        return false;
      }
      else {        
        this.addressForm.get('postcode')?.setErrors(null);
        this.submitForm();
        return true;
      }
    });
  }
  return false
  }
  
}
