import { Address } from "./user.model";
import {Shaker} from "./shaker.model"

export interface Quote {
    Id?: ObjectIdModel;
    quoteId: string;
    jobId: string;
    name: string;
    comment: string;
    includeOffCuts: boolean;
    panelsNeedsLabelling: boolean;
    collection: boolean;
    parts: Part[];
    boards: Board[];
    customerName:string;
    customerCompanyName:string;
    customerMobileNumber:string;
    edges: Edge[];
    designs: Design[];
    optimisingparameter: string;
    sawparameter: string;
    error: string;
    totalcuttingtime: string;
    totaledgingtime: string;
    status: number;
    customerId: string;
    totalinvoiceamount:string;
    numberOfParts:string;
    totalweight:string;
    weight:string;
    address:Address;
    leadtime:string;
    depot:number;
    deliveryDetails:DeliveryDetails
    shakermaterials : any[];
    shakers:Shaker[];
    createdOn:string;
    customerReference:string;
    IsOffCutQuote:boolean
    customerEmail:string;
    ExtraCostItems:string[];
  }

  export interface DeliveryDetails{
    neededBy:string;
    purchaseOrder:string;
    instructionDetails:string
    deliveryTiming:string
    depot:number;
  }
  export interface ObjectIdModel {
    Timestamp: number;
    Machine: number;
    Pid: number;
    Increment: number;
    CreationTime: string;
  }
  
  
  export interface Part {
    materialCode: string;
    materialDescription :string;
    length: string;
    width: string;
    quantity: string;
    grain: boolean;
    edgeCode: string;
    l1: string;
    l1Description: string;
    l2: string;
    l2Description: string;
    w1: string;
    w1Description: string;
    w2: string;
    w2Description: string;
    partComment: string;
    primed: boolean;
    operations: string;
    cuttingTime: string;
    edgingTime: string;
    name:string;
    weight:string;
  }
  
  export interface Edge {
    code: string;
    quantity: string;
    description: string;
  }
  
  export interface Board {
    code: string;
    quantity: string;
    description: string;
  }
  
  export interface Design {
    fileurl: string;
  }
  
  export const eStatusM = {
    0: "Drafted",
    1:"Submitted",
      2:"Error",
     3:"Quoted",
      4:"Won",
      5:"Expired",
      6:"NeedsReview",
      7:"Excluded",
  }
  export const eStatusO = {
    0: "In Progress",
    1:"Inspection",
      2:"Ready for Delivery",
     3:"Ready for Collection",
      4:"Complete",
      5:"Cancelled",
      6:"Waiting for Authorization"
  }
  
  export interface QuotationModel {
    id: string;
    quotationId: string;
    quoteId: string;
    totalnetamount: number;
    totalvatamount: number;
    totalinvoiceamount: number;
    carriagenetamount:number;
    details: QuotationDetails[];
  }
  
  export interface QuotationDetails {
    code: string;
    description: string;
    quantity: number;
    unitprice: number;
    netprice: number;
    vat: number;
    total: number;
  }
  
  export interface BoardsLibrary {
    id: string;
    length: number;
    width: number;
    sellingprice: number;
    stocktype: string;
    cost: number;
    code: string;
    quantity:string;
  }
  
  export interface EdgesLibrary {
    id: string;
    description: string;
    materialcode: string;
    warning: number;
    edgegrain: string;
    sellingprice: number;
    code: string;
    quantity:string;
  }
  
  export interface MaterialsLibrary {
    code: string;
    description: string;
    stock_codes: StockCodesModel[];
  }
  
  export interface StockCodesModel {
    code: string;
  }
  
  export interface CustomerModel {
    id: string;
    customerId: string;
    displayName: string;
    email: string;
    mobile: string;
  }
  // export interface Order {
  //   Id?: ObjectIdModel;
  //   orderId?: string;
  //   quoteId?: string;
  //   jobId?: string;
  //   jobReference?: string;
  //   invoiceReference?: string;
  //   status?: number;
  //   customerName?: string;
  //   customerCompanyName?: string;
  //   customerMobileNumber?: string;
  //   neededBy?: string;
  //   confirmed?: boolean;
  //   authorised?: boolean;
  //   customerId?: string;
  //   totalinvoiceamount?: number;
  //   name:string
  // }
  export enum eOrderStatus {
    InProgress = 0,
    Inspection = 1,
    ReadyForDelivery = 2,
    ReadyToCollect = 3,
    Complete = 4,
    Cancelled = 5,
    WaitingForAuthorization = 6
}

export enum eDeliveryWindow {
    SixAMtoEightAM = 0,
    EightAMtoTenAM = 1,
    TenAMtoTwelvePM = 2,
    TwelvePMtoTwoPM = 3,
    TwoPMtoFourPM = 4,
    FourPMtoSixPM = 6,
    SixPMtoEightPM = 8,
    EightPMtoTenPM = 9,
    TenPMtoTwelveAM = 10,
    TwelveAMto2AM = 11,
    TwoAMtoFourAM = 12,
    FourAMtoSixAM = 13
}


export const formattedDeliveryWindow: Record<number, string> = {
  0: "6AM to 8AM",
  1: "8AM to 10AM",
  2: "10AM to 12PM",
  3: "12PM to 2PM",
  4: "2PM to 4PM",
  6: "4PM to 6PM",
  8: "6PM to 8PM",
  9: "8PM to 10PM",
  10: "10PM to 12AM",
  11: "12AM to 2AM",
  12: "2AM to 4AM",
  13: "4AM to 6AM"
};

export interface DropModel {
    dropId: string;
    sequence: number;
    collection: boolean;
    weight: number;
    notes: string;
    totalparts: number;
    deliverywindow: eDeliveryWindow;
    confirmed: boolean;
    deliverydate:Date;
    dropcompleted: boolean;
}

export interface dropparts {
    linenumber: number;
    partnumber: number;
    material: string;
    length: string;
    width: string;
    name: string;
    primed: boolean;
    operations: string;
}

export interface UpdateProductionNotesForOrderRequest {
    Notes: string;
}

export interface Order {
  Id?: ObjectIdModel;
    orderId: string;
    quoteId: string;
    jobId: string;
    jobReference: string;
    orderReference:string;
    invoiceReference: string;
    status: eOrderStatus;
    customerName: string;
    customerCompanyName: string;
    customerMobileNumber: string;
    neededBy: string;
    confirmed: boolean;
    authorised: boolean;
    customerId: string;
    totalinvoiceamount: number;
    purchaseordernumber: string;
    collection: boolean;
    name: string;
    depot: eDepot;
    drops: DropModel[];
    jobCompletedOn: Date;
    customerEmail: string;
    productionNotes: string;
    isCompleted: boolean
    customerReference:string;
    paymentId:string;
    refundId : string;
    CollectionNotePrintedOn:string;
    createdOn:Date
}

enum eDepot {
  LHR = 0,            //Default Depot Hanworth
  BOH  = 1            //New Depot Poole
}

export interface LogDetails {
  createdon: Date;
  type: string;
  action: string;
  username: string;
  description: string;
}

export interface OrderLog {
  _id: string; // Assuming _id is the corresponding property for ObjectId
  quoteId: string;
  details: LogDetails[];
}
