import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address, User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent implements OnInit {
  personalInfoForm: FormGroup;
  address!:Address;
  user!:User;
  disableNext:boolean=false;
  formDisabled:boolean = false;

  constructor(private metadataService: MetadataService,private formBuilder: FormBuilder, private apiService:EndpointApiService) { 
    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    const userJSON = JSON.parse(userString!);
    if (token) {
      if (userJSON.roles.includes("admin")){
        this.user= JSON.parse(localStorage.getItem('tempUser')! as string);
        this.formDisabled=true
      }else{
      this.user = JSON.parse(userString as string)
      }
    }
    if(this.user.billingAddress){
      this.personalInfoForm = this.formBuilder.group({
        firstName: [this.user.firstName, Validators.required],
        lastName: [this.user.lastName, Validators.required],
        address: [this.user.billingAddress?.address, [Validators.required, Validators.maxLength(60)]],
        city: [this.user.billingAddress?.city, Validators.required],
        postCode: [this.user.billingAddress?.postcode, Validators.required]
      });
      //this.checkPostCode()
    } else {
      this.personalInfoForm = this.formBuilder.group({
        firstName: [this.user.firstName, Validators.required],
        lastName: [this.user.lastName, Validators.required],
        address: ["", Validators.required],
        city: ["", Validators.required],
        postCode: ["", Validators.required]
      });
    }
   
  }
  @Output() next = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();

  onNext() {
    // Process form data from Step 2 and emit it to the parent component    
    const postcode = this.personalInfoForm.get('postCode')?.value;
  
        if (this.personalInfoForm.valid) {
          if (!this.personalInfoForm.pristine) {
            this.user.billingAddress = this.personalInfoForm.value;
            //delete this.user.Id;
            this.apiService.updateUser(this.user, false).subscribe(data => {
              this.user = data as User;
              localStorage.setItem('user', JSON.stringify(data));
              this.next.emit(this.personalInfoForm.value);
            });
          } else {
            this.next.emit(this.personalInfoForm.value);
          }
        }
      
  }
  
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }

  onSubmit() {
    if (this.personalInfoForm.valid) {} else {}
  }
  goBack(){
    this.back.emit()
  }
  checkPostCode() {
    const postcode = this.personalInfoForm.get('postCode')?.value;
    if (postcode) {
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
      this.apiService.getPostCodeAzure(normalizedPostcode).subscribe((data: any) => {
    
        if (data["valid"] === false) {
          this.personalInfoForm.get('postCode')?.setErrors({ 'invalid': true });
          
        } else {
          this.onNext();
        }
      });
    }
  }  
  validateAddress(formatted_address:string){
    let splits = formatted_address.split(',')
    let address = splits[0]
    splits[splits.length - 2] =splits[splits.length - 2].trim()
    let city = splits[splits.length - 2].split(' ')[0]
    let address_f = this.personalInfoForm.get('address')?.value
    let city_f = this.personalInfoForm.get('city')?.value
    if(!address_f.includes(address)){
      this.personalInfoForm.get('address')?.setValue(address)
    }
    if(!city_f.includes(city)){
      this.personalInfoForm.get('city')?.setValue(city)
    }
  }
  checkError(control:string){
    return this.personalInfoForm.get(control)?.invalid;
  }
  setNextBlur(){
    this.disableNext=true;
  }
}
