<div *ngIf="detailsFetched" class="print-job-label-container">
    <table class="tbl">
        <thead>
            <tr>
                <th colspan="4">{{order.name}} - {{order.orderReference}} ({{order.jobReference}})</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td width="25%">Contact</td>
                <td colspan="3">{{order.customerName}} {{order.customerMobileNumber}}</td>
            </tr>
            <!-- <tr *ngIf="order.collection">
    
                    <td width="25%">Delivery Schedule</td>
                    <td colspan="3">COLLECTION<br>No date<br>No time</td>
                
            </tr> -->
            <!-- <tr *ngIf="!order.collection">
                    <td width="25%">Delivery Schedule</td>
                    <td *ngFor="let drop of order.drops" colspan="3">Delivery<br>{{drop.deliverydate ? (drop.deliverydate| date: 'dd/MM/yy') : ""}}<br>{{getDeliveryTime(drop.deliverywindow)}}</td>
            </tr> -->
            <tr >
                <td width="25%">Delivery Schedule</td>
                <td *ngFor="let drop of order.drops" colspan="3">{{order.collection? "Collection" : "Delivery"}}<br>{{order.neededBy | date: 'dd/MM/yy'}}<br>{{getDeliveryTime(drop.deliverywindow)}}</td>
            </tr>
            <tr>
                <td width="25%">Delivery comments</td>
                <td colspan="3">{{quote.deliveryDetails.instructionDetails}}</td>
            </tr>
            <tr>
                <td *ngIf="!order.collection" width="25%">Delivery Address</td>
                <td *ngIf="!order.collection">{{quote.address.address + "," + quote.address.city + "," +quote.address.postcode}}</td>
            </tr>
            <tr>
                <td width="25%">Job Weight</td>
                <td colspan="3">{{quote.includeOffCuts? epService.format(quote.totalweight):epService.format(quote.weight)}}kgs {{quote.includeOffCuts? "INCLUDE OFFCUTS":""}}</td>
            </tr>
            <tr></tr>
        </tbody>
    </table>
</div>