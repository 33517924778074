<div class="received-quoes">
    <div class="main-body">
        <div class="content-area">
            <div class="content-area-content ">
                <div>
                    <h2>Received quotes</h2> 
                    <div>
                        <div class="search">
                            <span class="search-body">
                                <div class="item">
                                    <div class="label">
                                        Find:
                                    </div>
                                    <div class="input">
                                        <input [(ngModel)]="quoteF" name="Root$Main$ctl00$txtKeywordSearch" type="text"
                                            id="Main_ctl00_txtKeywordSearch" class="txt textbox" (keyup.enter)="searchQuotes()">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        Created at:
                                    </div>
                                    <div class="input">
                                        <span id="Main_ctl00_spanCreatedAt">
                                            <input [(ngModel)]="fromDate" name="Root$Main$ctl00$txtCreatedAt" type="date"
                                                id="Main_ctl00_txtCreatedAt" class="txt date-box textbox" (keyup.enter)="searchQuotes()">
                                            <span id="Main_ctl00_cmvtxtCreatedAt" style="visibility:hidden;">*</span>
                                        </span>
                                        <span id="Main_ctl00_spanCreatedAt2">
                                            <label for="Main_ctl00_txtCreatedAt2" class="width-20">to</label>
                                            <input [(ngModel)]="toDate" name="Root$Main$ctl00$txtCreatedAt2" type="date"
                                                id="Main_ctl00_txtCreatedAt2" class="txt date-box textbox"  (keyup.enter)="searchQuotes()">

                                            <span id="Main_ctl00_cmvtxtCreatedAt2" style="visibility:hidden;">*</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        Status:
                                    </div>
                                    <div class="input">
                                        <select [(ngModel)]="statusF" name="Root$Main$ctl00$lstStatus" id="Main_ctl00_lstStatus" class="list" >
                                            <option value="-1" selected="selected" (keyup.enter)="searchQuotes()" >
                                                ---All---</option>
                                            <!-- <option value="">Cancelled</option> -->
                                            <option value="0">Draft</option>
                                            <option value="2">Error</option>
                                            <option value="7">Excluded</option>
                                            <option value="5">Expired</option>
                                            <!-- <option value="1">In progress</option>
                                            <option value="1">In queue</option> -->
                                            <option value="6">Needs review</option>
                                            <option value="1">Processing Order
                                            </option>
                                            <option value="3">Quoted</option>
                                            <option value="4">Won</option>

                                        </select>
                                    </div>
                                </div>
                                <span class="buttons" style="float: none; padding-left: 120px;">
                                    <input (click)="searchQuotes()"  type="submit" name="Root$Main$ctl00$btnSearch" value="Search"
                                        id="Main_ctl00_btnSearch">
                                </span>
                                <!-- <span class="buttons" style="float: none; padding-left: 20px;">
                                    <input (click)="fullSearch()"  type="submit" name="Root$Main$ctl00$btnSearch" value="Full Search"
                                        id="Main_ctl00_btnSearch" [disabled]="!isFullSearchEnabled()" [ngClass]="{'disabled-button': !isFullSearchEnabled()}">
                                </span> -->
                            </span>

                        </div>
                        <ng-template #loading>
                            <div class="loader">
                            <app-loader></app-loader>
                            </div>
                         </ng-template>
                        <div class="list" *ngIf='(quotes$ | async); else loading'>
                            <div>
                                <table class="grid" cellspacing="0" currentsort="item.CreatedAt DESC"
                                    id="Main_ctl00_gridList" style="border-collapse:collapse;table-layout:fixed; width:100%;overflow-wrap: break-word;">
                                    <tbody>
                                        <tr class="header-row">
                                            <th scope="col"><a
                                                >Reference</a>
                                            </th>
                                            <th scope="col"><a
                                                  >Name</a>
                                            </th>
                                            <th scope="col"><a
                                                   >Company
                                                    Name</a></th>
                                            <th scope="col"><a
                                                >Mobile Number</a></th>        
                                           
                                            <th scope="col"><a
                                                    >Your
                                                    Job Name</a></th>
                                            <th scope="col"><a
                                                    >Date</a>
                                            </th>
                                            <th scope="col"><a
                                                    >Total</a>
                                            </th>
                                            <th scope="col" style="width: 100px;"><a
                                                    >Status</a>
                                            </th>
                                            <th scope="col"><a
                                                    >Comments</a>
                                            </th>
                                            <th scope="col" style="width: 80px;"><a
                                                   >Error</a>
                                            </th>
                                            <th scope="col" style="width: 80px;"><a
                                                >Resend</a>
                                            </th>
                                        </tr>
                                        <div *ngIf="emptySearch" style="margin-top: 50px;">
                                            <b>NO RESULTS FOUND. SORRY.</b>
                                         </div>
                                        <tr *ngFor="let quote of quotes$ | async">
                                            <td>
                                                <a (click)="navigateToQuoteDetails(quote)" id="Main_ctl00_gridList_btnReference_0" [ngClass]="{'hyperlink': (getStatus(quote) == 'Quoted' || getStatus(quote) == 'Won') }"
                                                    >{{quote.jobId}}</a>
                                            </td>
                                            <td>
                                                {{quote.customerName}}
                                            </td>
                                            <td>
                                                {{quote.customerCompanyName}}
                                            </td>
                                            <td>
                                                {{quote.customerMobileNumber}}
                                            </td>
                                            
                                            <td>
                                                <a>{{quote.name}}</a>
                                            </td>
                                            <td>
                                                {{getFormattedDateTime(quote.Id.CreationTime)}}
                                            </td>
                                            <td>
                                                <span *ngIf="getStatus(quote) != 'NeedsReview'"> £{{getAmount(quote.totalinvoiceamount)  | number:'1.2-2'}}</span>
                                                <span *ngIf="getStatus(quote) == 'NeedsReview'"> £0.00</span>
                                            </td>
                                            <td>
                                                {{getStatus(quote)}}
                                            </td>
                                            <td>
                                                <a class="hyperlink" *ngIf="quote.comment" (click)="showError(quote.comment)">Show</a>
                                            </td>
                                            <td>
                                                <a class="hyperlink" *ngIf="quote.error" (click)="showError(quote.error)">Show</a>
                                            </td>
                                            
                                            <td>
                                                <input type="image" name="Root$Main$ctl00$gridList$ctl02$btnResend"
                                                    id="Main_ctl00_gridList_btnResend_0" title="Resend to magi-cut"
                                                    src="../../../assets/images/refresh.png" alt="Resend" (click)="reQuote(quote)"
                                                   >
                                            </td>
                                        </tr>
                                        <tr *ngIf="quickSearchYeilds" class="fullSearchButton" (click)="fullSearch()">Look For Older data</tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>