import { Component, HostListener, Inject, OnDestroy, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ADMIN_HEADERS } from 'src/app/admin/constant/headers.contant';
import { AdminHeaders } from 'src/app/admin/models/admin.models';
import { User } from 'src/app/models/user.model';
import { DataService } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { AlertService } from '../alert-set/alert.service';
import { SharedModule } from '../shared.module';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header-navigations',
  templateUrl: './header-navigations.component.html',
  styleUrls: ['./header-navigations.component.scss']
})
export class HeaderNavigationsComponent implements OnDestroy {
  loginPasswordField:boolean=false;
  hideHeader:boolean=false;
  signInForm: FormGroup;
  signUpForm: FormGroup;
  isSignedUp = false;
  userDisplayName = "";
  user: User;
  activeRoute = "";
  isImp = "false"
  isAdmin = false
  isLoading = false
  tandCLink = "";
  isLoginLoading = false;
  subMenu=true;
  isDropDown=true;
  isMenuHidden: boolean = false;
  expiredAndNeedReview="";
  postCodeValidity:boolean=false;
  sessionTimer:any;
  sessionExpiryTimerStarted: boolean = false;  
  bsModalRef!: BsModalRef;
  modalMessage: string="";
  modalHeader: string="";
  @ViewChild('sessionPopup', { static: true })
  sessionPopup!: TemplateRef<any>;
  headers: AdminHeaders[] = ADMIN_HEADERS;
  constructor(private formBuilder: FormBuilder,@Inject(PLATFORM_ID) private platformId: Object, private apiService: EndpointApiService, private router: Router, private _dataService: DataService,private alertService: AlertService,private modalService: BsModalService) {
    this.isLoggedIn = true;
    this.signInForm = this.formBuilder.group({
      signupFirstName: ['', [Validators.required, Validators.email]],
      signupPassword: ['', [Validators.required]],
      
    });
    if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 768){
      this.isDropDown=true;
      this.subMenu=false;
    }
    }
    this.getExpiredNeedReview();
    this._dataService.getHeaderFooterFlag().subscribe((data) => {      
      this.hideHeader=data;
    });
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((route: any) => {
      this.activeRoute = route.url;
    });
    this.apiService.getTermsAndC().subscribe((data: any) => {
      this.tandCLink = data
    })
    this._dataService.impersonateSubject.subscribe(data => {
      this.signInForm.setValue({ "signupFirstName": data.user.email, "signupPassword": data.user.password })
      if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("impersonate", "true")
      localStorage.setItem("admin", JSON.stringify(data.admin))
      }
      this.isImp = "true"
      this.onLoginSubmit(false)
    })
    this.signUpForm = this.formBuilder.group({
      displayName: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      companyName: [''],
      address: [''],
      postcode: ['', [Validators.required]],

      email: ['', [Validators.required, Validators.email]],
      mobile: [''],
      city: [''],


      notifications: [true],
      marketing: [false]
    });
    this.user = {
      displayName: "",
      firstName: "",
      lastName: "",
      password: "",
      roles: [],
      lastLogin: new Date(),
      companyName: "",
      customerType: "",
      address: [],
      postcode: "",
      status: false,
      email: "",
      mobile: "",
      city: "",
      userId: "",
      type: "",
      notifications: true,
      marketing: false,
      Id: {
        Timestamp: 1630865679,
        Machine: 12345,
        Pid: 6789,
        Increment: 1,
        CreationTime: "2021-09-05T12:34:56.789Z",
      },
      isLoggedIn: false,
      token: "",
    };
    if (isPlatformBrowser(this.platformId)) {
    const token = localStorage.getItem('token');
    this.isLoggedIn = !!token;
    const userString = localStorage.getItem('user');
    if (token) {
      this.isLoggedIn = true;
      this.user = JSON.parse(userString as string)
      this.userDisplayName = this.user.displayName;
      this.isAdmin = this.user.roles.includes("admin") ? true : false;
    }
    this.isImp = localStorage.getItem("impersonate") as string || "false"
  }
  }
  ngOnInit(){
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;
      const userString = localStorage.getItem('user');
      if (token) {
        this.isLoggedIn = true;
        this.user = JSON.parse(userString as string)
        this.userDisplayName = this.user.displayName;
        this.isAdmin = this.user.roles.includes("admin") ? true : false;
      }
      this.isImp = localStorage.getItem("impersonate") as string || "false";
    }  
  }
  changeNavBar(): void {

  }

  endImpFlow(logout = false) {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem("impersonate", "false")
    localStorage.removeItem("addressSelected")
    localStorage.removeItem("collection/delivery")
    localStorage.removeItem("offcuts")
    localStorage.removeItem("panels")
    localStorage.removeItem("userId")
    this.isImp = "false";
    if (logout == false) {
      this.isLoggedIn = true;
      const userString = localStorage.getItem('admin');
      var admin = JSON.parse(userString as string)
      this.signInForm.setValue({ "signupFirstName": admin.email, "signupPassword": admin.password })
      this.onLoginSubmit(false)
      localStorage.setItem("impersonate", "false");
      this.userDisplayName = this.user.displayName;
    }
    }
  }
  ngOnDestroy(): void {
    // localStorage.clear();
  }
 
  isSignupOn: boolean = false;
  isSignIn: boolean = false;
  isForgotPassword: boolean = false;
  isLoggedIn = false;
  isErrorWithLoggedIn: boolean = false;
  fpEmail = ""
  signUp(event: Event): void {
    event.stopPropagation();
    this.isSignIn = false;
    this.isSignupOn = !this.isSignupOn;
    this.isForgotPassword = false;
  }

  stopEventPropgate($event: Event): void {
    $event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    this.isSignIn = false;
    this.isSignupOn = false;
    this.isForgotPassword = false;
  }

  signIn(event: Event): void {
    event.stopPropagation();
    this.isSignupOn = false;
    this.isSignIn = !this.isSignIn;
    this.isForgotPassword = false;
    this.getExpiredNeedReview()
  }

  forgotPassword(event: Event): void {
    event.stopPropagation();
    this.isSignIn = false;
    this.isSignupOn = false;
    this.isForgotPassword = true;
  }
  getExpiredNeedReview() {
    if (isPlatformBrowser(this.platformId)) {
    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    this.user = JSON.parse(userString as string)
    if ((token) && (this.user.roles.includes("admin"))) {
        this.apiService.getExpiredAndNeedReview().subscribe((data: any) => {
        this.expiredAndNeedReview = JSON.stringify(data.NumberOfQuotesExpiredOrNeedsReview);
      })
    }
  }
  }

  toggleDropDown(){
    if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 1025) {
    this.isDropDown=!this.isDropDown;
    }
  }
  }

  toggleBurgerMenu(){
    if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 1025) {
    this.isMenuHidden = !this.isMenuHidden
    }
  }
  }
  toggleSubMenu(){
    if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 1025) {
    this.subMenu= !this.subMenu;
    }
  }
  }
  turnOffSubMenu(){
    if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 1025) {
      this.subMenu=false;
      }
    }
  }
  toggleLoginPassword(){
    this.loginPasswordField= !this.loginPasswordField;
  }
  cancelReset(): void {
    this.isForgotPassword = false;
  }
  handleFpPassword(){
    this.apiService.forgotPassword(this.fpEmail).subscribe(data => {
      this.isForgotPassword = false;
    })
  }
  onLogout() {
    clearTimeout(this.sessionTimer);
    this.sessionExpiryTimerStarted = false;
    this.apiService.logout({ "email": this.user.email, "password": this.user.password }).subscribe(data => {
      this.isLoggedIn = false;
      if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
      }
      this.isAdmin = false;
      this.endImpFlow(true);
      this.router.navigate(['/']);
    })
  }
  onLoginSubmit(encrypt = true) {
    if (this.signInForm.valid) {
      // Perform login logic here
      this.isLoginLoading = true
      const email = this.signInForm.value.signupFirstName;
      const password = this.signInForm.value.signupPassword;
      this.apiService.login({ "email": email, "password": password }, encrypt).subscribe((data) => {
        this.user = data as User;
        this.userDisplayName = (data as User).displayName;
        if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('token', (data as User).token);
        localStorage.setItem('user', JSON.stringify(data));
        }
        this.isLoggedIn = true;
        this.isLoginLoading = false;
        this.isErrorWithLoggedIn = false;
        if (this.user.roles.includes("admin")) {
          this.isAdmin = true
          this.router.navigate(['/admin'])
          this.getExpiredNeedReview()
        } else {
          this.isAdmin = false
          this.router.navigate(['/customer/my-quote', this.user.userId])
        }
      }, (err) => {
        this.isErrorWithLoggedIn = true;
        this.isLoginLoading = false;
      });
      if (!this.sessionExpiryTimerStarted) {
        this.sessionTimer=setTimeout(() => {
            this.calculateSessionExpiryWarning();
        },480 * 60 * 1000); // 480 minutes converted to milliseconds
        this.sessionExpiryTimerStarted = true;
    }
    }
  }

  onSignupSubmit() {
    this.checkPostCode()
    if (this.signUpForm.valid && this.postCodeValidity) {
      const formData = this.signUpForm.value;

      // const firstAlphabetFirstName = this.signUpForm.value.firstName.charAt(0).toUpperCase();
      // const firstAlphabetLastName = this.signUpForm.value.lastName.charAt(0).toUpperCase();
      formData.displayName = this.signUpForm.value.firstName;
      formData.billingAddress={"address": this.signUpForm.value.address, "city": this.signUpForm.value.city, "postcode": this.signUpForm.value.postcode}
      
      formData.address = [{ "address": this.signUpForm.value.address, "city": this.signUpForm.value.city, "postcode": this.signUpForm.value.postcode }];
  
      this.apiService.addUser(formData).subscribe({
        next: (data) => {
          this.isSignedUp = true;
          this.alertService.showSignUpSuccess(true);
          },
        error: (error) => {
          this.isErrorWithLoggedIn = true;
          this.alertService.showSignUpDecline(true);
        }
      });
    }
  }
  checkPostCode() {
    const postcode = this.signUpForm.value.postcode;
    if (postcode) {
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
      this.apiService.getPostCodeAzure(normalizedPostcode).subscribe((data: any) => {
        if (data["valid"] === false) {
          this.signUpForm.get('postCode')?.setErrors({ 'invalid': true });
          this.postCodeValidity=false;
        } else {
          this.validateAddress(data["formatted_address"]);
          this.postCodeValidity=true;
        }
      });
    }
  }  
  
  validateAddress(formatted_address:string){
    let splits = formatted_address.split(',')
    let address = splits[0]
    splits[splits.length - 2] =splits[splits.length - 2].trim()
    let city = splits[splits.length - 2].split(' ')[0]
    let address_f = this.signUpForm.get('address')?.value
    let city_f = this.signUpForm.get('city')?.value
    if(!address_f.includes(address)){
      this.signUpForm.get('address')?.setValue(address)
    }
    if(!city_f.includes(city)){
      this.signUpForm.get('city')?.setValue(city)
    }
  }

  closeError(): void {
    this.isErrorWithLoggedIn = false;
  }
  openModal(template: TemplateRef<any>, header:string, message:string): void {
    this.modalHeader=header;
    this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  calculateSessionExpiryWarning(): void {
    this.sessionExpiryTimerStarted = false;
    this.openModal(this.sessionPopup,'Session Expired','Session Expired. Please login again to continue.');
  }
  closeModal(): void {
    this.onLogout();
    this.bsModalRef.hide();
  }

}
