
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environment.uat';

@Component({
  selector: 'app-old-urls',
  standalone: true,
  imports: [],
  template: `<p>Redirecting...</p>`
})
export class OldUrlsComponent {

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    
    const boardId = this.route.snapshot.paramMap.get('boardId');
    
    const encodedBoardId = encodeURIComponent(boardId!);
  
    const newUrl = `${environment.HOST_URL}/choose-your-boards/${encodedBoardId}`;
  
    this.titleService.setTitle('Redirecting to your board...');
  
    // Add the meta refresh tag
    this.metaService.addTag({
      httpEquiv: 'refresh',
      content: `0;url=${newUrl}`
    });
  }
  


}
